<template>
    <the-secrets-listing :level="0" />
</template>

<script>
const TheSecretsListing = () => import('@/components/TheSecretsListing.vue')

export default {
    components: { TheSecretsListing }
}
</script>
